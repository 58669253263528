var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "inline-block relative m-auto"
  }, [_vm._t("default", function () {
    return [_c('inline-svg', {
      attrs: {
        "src": _vm.iconNotification,
        "height": "19",
        "width": "19",
        "fill": "#323232"
      }
    })];
  }), _c('CBox', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show || _vm.show >= 1,
      expression: "show || show >= 1"
    }],
    staticClass: "bg-red-500 inline-block absolute",
    attrs: {
      "px": "2px",
      "rounded": "1000px",
      "h": _vm.show >= 1 && typeof _vm.show === 'number' ? 'inherit' : '5px',
      "w": _vm.show >= 1 && typeof _vm.show === 'number' ? 'inherit' : '5px',
      "top": _vm.show >= 1 && typeof _vm.show === 'number' ? '-8px' : '0.5px',
      "right": _vm.show >= 1 && typeof _vm.show === 'number' ? '-10px' : '0px'
    }
  }, [_vm.show >= 1 && typeof _vm.show === 'number' ? _c('CText', {
    attrs: {
      "d": "flex",
      "justify-content": "center",
      "align-items": "center",
      "h": "16px",
      "w": "16px",
      "font-size": "9px",
      "color": "white",
      "px": "8px"
    }
  }, [_vm._v(" " + _vm._s(_vm.show > 99 ? "99+" : _vm.show) + " ")]) : _vm._e()], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }