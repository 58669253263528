<template>
  <div class="inline-block relative m-auto">
    <slot>
      <inline-svg
        :src="iconNotification"
        height="19"
        width="19"
        fill="#323232"
      />
    </slot>
    <CBox
      v-show="show || show >= 1"
      class="bg-red-500 inline-block absolute"
      px="2px"
      rounded="1000px"
      :h="show >= 1 && typeof show === 'number' ? 'inherit' : '5px'"
      :w="show >= 1 && typeof show === 'number' ? 'inherit' : '5px'"
      :top="show >= 1 && typeof show === 'number' ? '-8px' : '0.5px'"
      :right="show >= 1 && typeof show === 'number' ? '-10px' : '0px'"
    >
      <CText
        v-if="show >= 1 && typeof show === 'number'"
        d="flex"
        justify-content="center"
        align-items="center"
        h="16px"
        w="16px"
        font-size="9px"
        color="white"
        px="8px"
      >
        {{ show > 99 ? "99+" : show }}
      </CText>
    </CBox>
  </div>
</template>

<script >
import iconNotification from '@/assets/icons/icon-notification.svg';
const __sfc_main = {};
__sfc_main.props = {
  show: {
    type: [Boolean, Number],
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  return {
    iconNotification
  };
};

export default __sfc_main;
</script>